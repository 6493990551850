.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

:root {
    --dsg-selection-border-color: #1976D2;
    --dsg-selection-background-color: #32323D33;
    --dsg-header-text-color: #424242;
    --dsg-header-active-text-color: #1976D2;
    --dsg-scroll-shadow-color: #ff0000;
    --dsg-cell-background-color: #FAFAFA;
    --dsg-scroll-shadow-width: 2px;
    --dsg-border-color: #AAAAAA;
    --dsg-selection-disabled-background-color: #AAAABB;
    --dsg-cell-disabled-background-color: #CCCCDD;
}

.dsg-cell-disabled.dsg-cell-focused {
    color: #666666 !important;
}